import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';

export const Container = styled.div`
  @media ${device.tablet} {
    margin-top: 3.125rem;
    margin-bottom: 10rem;
  }

  @media ${device.mobile} {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
`;

export const Content = styled(ReactMarkdown)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 2rem;
  }

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 24px;
  }

  ul li {
    display: list-item;
    border-bottom: none !important;
    list-style-type: disc !important;
    padding: 0;
    margin-bottom: 0;
    margin-left: 1.2rem;
  }
`;

export const Image = styled.img`
  position: absolute;
  z-index: 1;
  transform: translate(120%, -20%);

  @media ${device.tablet} {
    width: 188px;
    transform: translate(160%, -60px);
  }

  @media ${device.mobile} {
    width: 138px;
    height: 189px;
    transform: translate(188%, -95px);
  }

  @media ${device.mobile320} {
    width: 100px;
    height: unset;
    transform: translate(259%, -95px);
  }
`;
