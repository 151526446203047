// Libs
import React from 'react';
import ReactMarkdown from 'react-markdown';

// Styles
import { Container } from './style';

function AcessoriaImprensa(props) {
  return (
    <Container
      marginTop={props.marginTop}
      contentPostAtrr={props.contentPostAtrr}
      fontSize320={props.fontSize320}
    >
      <ReactMarkdown children={props.markdown}></ReactMarkdown>
    </Container>
  );
}

export default AcessoriaImprensa;
