import React from 'react';

import * as S from './style';

const ResultNotFoundMessage = props => {
  const { fragments } = props.page[0];
  const findFragment = fragments.find(item => item.id === props.fragmentId);
  const title =
    findFragment.localizations[0].markdown.split('\n')[0] +
    ' ' +
    `<b>${props.titleSubmited}</b>` +
    '\n';

  const suggestions = findFragment.localizations[0].markdown.split('\n');
  suggestions.shift();

  return (
    <S.Container>
      <S.Content children={title + suggestions.join('\n')} escapeHtml={false} />
      <S.Image src={findFragment.assetpicker.url} alt="" />
    </S.Container>
  );
};

export default ResultNotFoundMessage;
