// Libs
import React, { useContext } from 'react';
// Styles
import { Container, ContentPost, SubjectText } from './style';
// import PaginationComponent from '../PaginationComponent';
import PaginationGeneral from '../paginationGeneral';
import { PaginationContext } from '../../context/pagination';
import nextIcon from 'images/icons/arrow-right.svg';

import { format } from 'date-fns';

// components
import ResultNotFoundMessage from './ResultNotFoundMessage';

function ListImprensa({
  listItens,
  typeSearch,
  page,
  fragmentId,
  titleSubmited,
}) {
  const { pagination } = useContext(PaginationContext);

  const newArr = pagination.map(item => {
    let newData = format(new Date(item.dataEHora), 'dd/MM/yyyy');
    let newHora = format(new Date(item.dataEHora), 'HH:mm');
    return { ...item, dataPost: newData, horaPost: newHora };
  });

  return (
    <Container>
      {!newArr.length && titleSubmited ? (
        <ResultNotFoundMessage
          page={page}
          fragmentId={fragmentId}
          titleSubmited={titleSubmited}
        />
      ) : (
        <>
          {/* Item Chumbado */}
          {typeSearch === 'BoletinsMedicos' && (
            <ul>
              <li>
                <div className="titulo">
                  <ContentPost to="https://hsllanding.s3.us-east-1.amazonaws.com/hsllanding/BM_LILS.pdf">
                    <span>
                      Boletim Médico - Luiz Inácio Lula da Silva (Última
                      atualização)
                    </span>
                  </ContentPost>
                </div>
                <div>
                  <ContentPost
                    to={
                      'https://hsllanding.s3.us-east-1.amazonaws.com/hsllanding/BM_LILS.pdf'
                    }
                  >
                    <img src={nextIcon} alt="next" />
                  </ContentPost>
                </div>
              </li>
            </ul>
          )}

          <ul>
            {newArr.map((item, i) => (
              <li key={item.title + i}>
                <div>
                  <div className="titulo">
                    <ContentPost to={`./${item.slugPost}`}>
                      <span>{item.title}</span>
                    </ContentPost>
                  </div>
                  <div className="contentList">
                    <span>Publicado em </span>
                    {typeSearch == 'PressReleases' && (
                      <span>{item?.dataPost} </span>
                    )}
                    {typeSearch !== 'PressReleases' && (
                      <>
                        <span>{item?.dataPost} </span>
                        <span>às </span>
                        <span>{item?.horaPost}</span>
                      </>
                    )}
                  </div>
                  <div className="contentList">
                    {typeSearch === 'PressReleases' && (
                      <>
                        <span>Assunto(s): </span>
                        {item.blogPressreleasesAssunto.map((i, index, arr) => (
                          <SubjectText
                            key={i.assunto}
                            lastItem={index === arr.length - 1}
                          >
                            {i.assunto}
                          </SubjectText>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <ContentPost to={`./${item.slugPost}`}>
                    <img src={nextIcon} alt="next" />
                  </ContentPost>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
      <PaginationGeneral dataPagination={listItens} team paddingBottom={30} />
    </Container>
  );
}
export default ListImprensa;
