// Libs
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
// import ReactMarkdown from 'react-markdown';
// Components
import { device } from 'components/device';
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 50%;
  @media ${device.tablet} {
    width: 100%;
    margin-top: 0;
  }
  @media only screen and (max-width: 320px) {
    padding: 0 1.25rem;
  }
  ul {
    margin: 0;
    li {
      list-style: none;
      border-bottom: 1px solid #c6c8cc;
      padding: 2px 0 17px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titulo {
        a {
          font-size: 16px;
          font-weight: 600;
          color: #231f20;
          }
          @media ${device.laptop} {
            width: 262px;
          }
          width: 470px;
        }
      }
      .contentList {
        color: #8f9194;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
  .pagination {
    li {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;
export const ContentPost = styled(Link)`
  img {
    margin-top: 9px;
  }
`;

export const SubjectText = styled.span`
  ${props =>
    props.lastItem
      ? css`
          &::after {
            content: '';
          }
        `
      : css`
          &::after {
            content: ', ';
          }
        `}
`;
