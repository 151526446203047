// Libs
import styled from 'styled-components';
// import ReactMarkdown from 'react-markdown';

// Components
import { device } from 'components/device';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 470px;
  background-color: #f4f5f7;
  margin-top: ${props => (props.marginTop ? '0' : '50px')};
  border-radius: 8px;
  padding: 30px;
  color: #231f20;
  max-height: 540px;
  @media ${device.mobile} {
    width: 335px;
    height: 484px;
    padding: 20px;
  }

  @media ${device.tablet} {
    margin: 50px auto;
  }

  @media only screen and (max-width: 320px) {
    width: ${props => (props.contentPostAtrr ? '280px' : '303px')};
    padding: 17px;
    max-height: ${props => (props.contentPostAtrr ? '620px' : '536px')};
    margin: ${props => (props.contentPostAtrr ? '30px 0' : '30px 15px')};
  }

  @media only screen and (max-width: 1024px) {
    max-height: 503px;
  }

  p:nth-child(2) {
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 400;
    @media ${device.laptop} {
      font-size: 18px;
      font-weight: normal;
    }
  }

  p {
    margin: 1.25rem 0 0.625rem;
    @media ${device.laptop} {
      margin: 1.25rem 0 0;
    }
    strong {
      font-size: 1rem;
      @media ${device.laptop} {
        font-size: 0.875rem;
      }
    }
    img {
      transform: translate(0, 27%);
      margin-right: 6px;
    }
    em {
      color: #45a7df;
      font-style: normal;
      a {
        color: #45a7df;
        @media screen and (max-width: 320px) {
          font-size: ${props => (props.fontSize320 ? '0.805rem' : '0.875rem')};
        }
        @media screen and (min-width: 360px) {
          font-size: 0.875rem;
        }
        @media (max-width: 320px) {
          font-size: ${props => props.fontSize320 && '0.805rem'};
        }
      }
    }
  }
`;
