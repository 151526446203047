// Libs
import React, { useState, useContext, useEffect } from 'react';
// Styles
import { Container } from './style';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Search from '../Search';
import { TabsContext } from '../../context/tabs';
import { device } from 'components/device';

import styled from 'styled-components';

const TabsMenu = styled.div`
  border-bottom: 1px solid #c6c8cc;
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  overflow-x: hidden;

  @media ${device.laptop} {
    grid-template-columns: auto repeat(5, 70px) auto;
  }

  @media ${device.mobile} {
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  > div {
    grid-column: 2 / span 12;
    display: flex;
  }
`;

const TabStyled = styled.button`
  text-transform: capitalize;
  font-size: 16px;
  margin-right: 2.1rem;
  font-weight: 500;
  line-height: 1.75;
  font-style: bold;
  letter-spacing: 0.02857em;
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  color: ${({ value, index }) => (value === index ? '#45a7df' : '#000')};
  left: -1rem;
`;

const TabBorder = styled.span`
  width: 105%;
  height: 2px;
  background-color: #45a7df;
  display: ${({ value, index }) => (value === index ? 'inline' : 'none')};
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className={'tabpanel'}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const SimpleTabs = props => {
  const { setTabs, activeTabs, setActiveTabs } = useContext(TabsContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(activeTabs.index || 0);
  const [typeSearch, setTypeSearch] = useState(
    activeTabs.name || 'PressReleases'
  );
  const ref = React.useRef();

  const handleChange = newValue => {
    switch (newValue) {
      case 0:
        setTypeSearch('PressReleases');
        ref.current.scrollTo(0, 0);
        break;
      case 1:
        setTypeSearch('BoletinsMedicos');
        ref.current.scrollTo(155, 0);
        break;
      default:
        setTypeSearch('NotasDeFalecimento');
        ref.current.scrollTo(355, 0);
      }
      setValue(newValue);
  };

  useEffect(() => {
    if (typeSearch == 'PressReleases') {
      setTabs('Press-Releases');
      setActiveTabs({ name: 'PressReleases', index: 0 });
    } else if (typeSearch == 'BoletinsMedicos') {
      setTabs('Boletins Médicos');
      setActiveTabs({ name: 'BoletinsMedicos', index: 1 });
    } else if (typeSearch == 'NotasDeFalecimento') {
      setTabs('Notas de Falecimento');
      setActiveTabs({ name: 'NotasDeFalecimento', index: 2 });
    }
  }, [typeSearch]);

  return (
    <Container>
      <div className={classes.root}>
        <TabsMenu ref={ref} value={value}>
          <div>
            <TabStyled
              value={value}
              index={0}
              onClick={() => {
                handleChange(0);
              }}
            >
              Press-Releases
              <TabBorder value={value} index={0} />
            </TabStyled>
            <TabStyled
              value={value}
              index={1}
              onClick={() => {
                handleChange(1);
              }}
            >
              Boletins Médicos
              <TabBorder value={value} index={1} />
            </TabStyled>
            <TabStyled
              value={value}
              index={2}
              onClick={() => {
                handleChange(2);
              }}
            >
              Notas de Falecimento
              <TabBorder value={value} index={2} />
            </TabStyled>
          </div>
        </TabsMenu>
        <TabPanel value={value} index={0}>
          <Search
            typeSearch={typeSearch}
            options={props.options}
            page={props.page}
            fragmentId={props.fragmentId}
          ></Search>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Search
            typeSearch={typeSearch}
            options={props.options}
            page={props.page}
            fragmentId={props.fragmentId}
          ></Search>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Search
            typeSearch={typeSearch}
            options={props.options}
            page={props.page}
            fragmentId={props.fragmentId}
          ></Search>
        </TabPanel>
      </div>
    </Container>
  );
};
export default SimpleTabs;
