// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
// Images
import dropdownIcon from 'images/icons/arrow-down-lightblue.svg';

import { device } from '../device';

import ListImprensa from '../ListImprensa';
import AcessoriaImprensa from '../AcessoriaImprensa';

const ContainerGrid = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: 100%;
    display: ${props =>
      props.modalIsOpen === true
        ? 'flex'
        : props.modalIsOpen === false
        ? 'none'
        : 'flex'};

    ${({ isNotDataResult }) =>
      !isNotDataResult &&
      `
      z-index: 5;
      position: relative;      
      left: 0;
      padding: 0 1.25rem;
      margin-top: 0;
      background: #fff;      
    `}
  }
  @media ${device.mobile} {
    top: ${props => props.isResults && '6rem'};
  }
`;

const GridContent = styled.div`
  display: flex;

  @media ${device.mobile} {
    width: auto;
    display: flex;
    flex-direction: column;
  }
`;

const GridContentFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Container = styled.form`
  padding: 1.875rem;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: space-between;
  align-items: center;
  text-align: start;
  border: 0.063rem solid #c6c8cc;
  border-radius: 0.75rem;
  background: #ffffff;

  @media ${device.mobile} {
    position: relative;
    padding: 1.25rem;
    margin-bottom: 2.1875rem;
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 979px) {
    width: 666px;
  }
`;

const BoxInput = styled.span`
  width: ${props => (props.boletimNota ? '62%' : '29%')};
  text-align: start;
  padding-right: 3rem;
  position: relative;

  :last-child {
    padding-right: 1.25rem;

    @media ${device.laptop} {
      padding-right: 0;
      padding-bottom: 1.25rem;
    }
  }

  @media ${device.mobile} {
    width: 100%;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 1.875rem;
    padding-right: 3rem;
  }
  @media ${device.tablet} {
    padding-right: 1rem;
  }
`;

const Button = styled.button`
  width: 86px;
  height: 45px;
  background: #45a7df;
  font-size: 0.875rem;
  opacity: ${props => !props.isButtonActive && '50%'};
  border: 0;
  border-radius: 6.25rem;
  color: #fff;
  margin: 0;
  padding: 0;
  outline: none;
  transition: 0.6s background;
  cursor: ${props => (!props.isButtonActive ? 'auto' : 'pointer')};
  :hover {
    background: ${props => (!props.isButtonActive ? '#c6c8cc' : '#1d52a3')};
  }

  @media ${device.mobile} {
    width: 100%;
    height: 3.5rem;
    font-size: 0.875rem;
    :hover {
      background: #45a7df;
    }
  }
`;

const TitleInput = styled.h2`
  width: 100%;
  font-size: 0.875rem;
  margin: 0;
  padding-bottom: 0.625rem;
`;

const Select = styled.div`
  width: 100%;
  border: none;
  border-bottom: ${props =>
    props.isError ? '0.063rem solid red' : '0.063rem solid #c6c8cc'};
  padding-bottom: 0.5rem;
  margin: 0;
  font-size: 0.875rem;
  outline: none;
  position: relative;
  cursor: pointer;

  @media ${device.laptop} {
    width: 95%;
  }

  @media ${device.mobile} {
    width: 100%;
  }

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
  }

  p {
    color: ${props => (props.isSelectedDropDown ? '#000' : '#999')};
  }

  img {
    cursor: pointer;
    transform: rotate(180deg);
    transform: ${props =>
      props.isDropdown ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.5s;
  }
`;

const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: ${props =>
    props.isError ? '0.063rem solid red' : '0.063rem solid #c6c8cc'};
  padding-bottom: 0.2rem;
  margin: 0;
  font-size: 0.875rem;
  background: transparent;
  outline: none;
  @media ${device.laptop} {
    padding-bottom: 0;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  width: 90%;
  border: 0.063rem solid #c6c8cc;
  border-radius: 0.75rem;
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background: #fff;
  animation: lideOut 0.5s ease-in-out;
  height: auto;
  max-height: 19vh;
  overflow-y: scroll;
  z-index: 99;

  @media ${device.laptop} {
    width: 100%;
  }

  @keyframes SlideOut {
    to {
      height: 19vh;
    }
    from {
      height: 0;
    }
  }

  @keyframes SlideIn {
    to {
      height: 0;
    }
    from {
      height: 19vh;
    }
  }

  ::-webkit-scrollbar {
    padding: 0 0.5rem;
    width: 0.25rem;
  }
  ::-webkit-scrollbar-thumb {
    margin-right: 0.625rem;
    width: 0.25rem;
    height: 0.125rem;
    border-radius: 0.5rem;
    background: #c6c8cc;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;

    p {
      height: 2.5rem;
      cursor: pointer;
      padding: 0.5rem;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      padding-left: 1rem;

      :hover {
        background: #f4f5f7;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

function Search({ posts, page, typeSearch, options, fragmentId }) {
  const [years, setYears] = useState([]);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDropdownYear, setIsDropdownYear] = useState(false);
  const [isDropdownAssunto, setIsDropdownAssunto] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(true);
  const [yearSelected, setYearSelected] = useState('');
  const [assuntoSelected, setAssuntoSelected] = useState('');
  const [previousSubjectSelected, setPreviousSubjectSelected] = useState(false);
  const [titleSelected, setTitleSelected] = useState('');
  // const [boletimSelected, setBoletimSelected] = useState('');
  const [listPosts, setListPosts] = useState(posts);
  const [listPostsFiltered, setListPostsFiltered] = useState('');
  const [listAssuntos, setListAssunsto] = useState('');
  const [titleSubmited, setTitleSubmited] = useState('');

  const getOptions = filtered => {
    const searchYears = filtered.map(item =>
      new Date(item.dataEHora).getFullYear()
    );
    const searchYearsFiltered = searchYears.filter(
      (el, i) => searchYears.indexOf(el) === i
    );
    searchYearsFiltered.sort().reverse();

    let yearsForSelect = [];
    for (let valor of searchYearsFiltered) {
      yearsForSelect.push({
        value: valor,
        label: valor,
      });
    }

    setYears(yearsForSelect);
  };

  const getAssuntos = () => {
    const assuntosPosts = posts;
    let assuntosForSelect = [];
    var assuntosSanialize = [];

    assuntosPosts.filter((item, i) => {
      item.blogPressreleasesAssunto.find(y => {
        assuntosForSelect.push(y.assunto);
      });
    });

    assuntosSanialize = assuntosForSelect.filter(
      (el, index) => assuntosForSelect.indexOf(el) === index
    );

    setListAssunsto(assuntosSanialize);

    if (options.length > 0) {
      const hasPreviousSubjectSelected = assuntosSanialize.find(subject => {
        const querySubject = options.find(option => option.key === 'assunto');
        return (
          subject
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase() ===
          querySubject.value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLocaleLowerCase()
        );
      });
      setPreviousSubjectSelected(hasPreviousSubjectSelected);
    }
  };

  const handleYear = value => {
    setYearSelected(value);
    setIsDropdown(false);
  };

  const handleAssunto = value => {
    setAssuntoSelected(value);
    setIsDropdownAssunto(false);
  };

  const handleTitle = ev => {
    let valueTitle = ev.target.value;

    setTitleSelected(valueTitle);
  };

  const loadPosts = (previousSubject = '') => {
    let allPostsPressRelease2 = listPosts;

    const selectedSubject = previousSubject || assuntoSelected;

    if (yearSelected !== '') {
      allPostsPressRelease2 = allPostsPressRelease2.filter(
        post => new Date(post.dataEHora).getFullYear() === yearSelected
      );
    }

    if (selectedSubject !== '') {
      allPostsPressRelease2 = allPostsPressRelease2.filter(post =>
        post.blogPressreleasesAssunto.find(i =>
          i.assunto.includes(selectedSubject)
        )
      );
    }

    if (titleSelected !== '') {
      allPostsPressRelease2 = allPostsPressRelease2.filter(item =>
        item.title
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            titleSelected
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          )
      );
    }

    setListPostsFiltered(allPostsPressRelease2);
  };

  const searchPost = value => {
    value.preventDefault();

    loadPosts();
    setTitleSubmited(titleSelected)
  };

  const openDropdown = ev => {
    ev.stopPropagation();
    setIsButtonActive(true);
    let dropdown = ev.target.getAttribute('name');

    // eslint-disable-next-line default-case
    switch (dropdown) {
      case undefined:
        return setIsDropdown(!isDropdown);
      case 'years':
        setIsDropdownYear(false);
        return setIsDropdown(!isDropdown);
      case 'assunto':
        setIsDropdown(false);
        return setIsDropdownAssunto(!isDropdownAssunto);
    }
  };

  useEffect(() => {
    getAssuntos();
  }, []);

  useEffect(() => {
    if (previousSubjectSelected) {
      handleAssunto(previousSubjectSelected);
      loadPosts(previousSubjectSelected);
    }
  }, [previousSubjectSelected]);

  useEffect(() => {
    let filterPost = posts.filter(post => post.typeOfPost.includes(typeSearch));
    setListPosts(filterPost);
    setListPostsFiltered(filterPost);
    getOptions(filterPost);
  }, [typeSearch]);

  return (
    <ContainerGrid onClick={() => setIsDropdown(false)}>
      <GridContent>
        <Container>
          <BoxInput>
            <TitleInput>Filtrar por ano</TitleInput>
            <Select
              name="years"
              isDropdown={isDropdown}
              isDropdownYear={isDropdownYear}
              onClick={ev => openDropdown(ev)}
            >
              <span>
                {yearSelected !== '' ? (
                  <p style={{ color: '#000' }}>{yearSelected}</p>
                ) : (
                  <p>Todos</p>
                )}
                <img
                  name="years"
                  src={dropdownIcon}
                  alt="dropdown-icon"
                  onClick={ev => openDropdown(ev)}
                />
              </span>
            </Select>
            {isDropdown && (
              <Dropdown>
                <>
                  <div>
                    <p onClick={() => handleYear('')} style={{ color: '#999' }}>
                      Todos
                    </p>
                    {years.sort().map((item, i) => (
                      <p
                        key={item.value}
                        onClick={() => handleYear(item.value) || ''}
                      >
                        {item.label}
                      </p>
                    ))}
                  </div>
                </>
              </Dropdown>
            )}
          </BoxInput>
          {typeSearch === 'PressReleases' && (
            <BoxInput>
              <TitleInput>Filtrar por assunto</TitleInput>
              <Select
                name="assunto"
                isDropdown={isDropdown}
                isDropdownAssunto={isDropdownAssunto}
                onClick={ev => openDropdown(ev)}
              >
                <span>
                  {assuntoSelected !== '' ? (
                    <p style={{ color: '#000' }}>{assuntoSelected}</p>
                  ) : (
                    <p>Todos</p>
                  )}
                  <img
                    name="assunto"
                    src={dropdownIcon}
                    alt="dropdown-icon"
                    onClick={ev => openDropdown(ev)}
                  />
                </span>
              </Select>
              {isDropdownAssunto && (
                <Dropdown>
                  <>
                    <div>
                      <p
                        onClick={() => handleAssunto('')}
                        style={{ color: '#999' }}
                      >
                        Todos
                      </p>
                      {listAssuntos.sort().map((item, i) => (
                        <p key={i} onClick={() => handleAssunto(item) || ''}>
                          {item}
                        </p>
                      ))}
                    </div>
                  </>
                </Dropdown>
              )}
            </BoxInput>
          )}
          {typeSearch === 'BoletinsMedicos' && (
            <BoxInput boletimNota>
              <TitleInput>Filtrar por Boletim</TitleInput>
              <Input
                placeholder="Digite o nome do Boletim Médico"
                type="text"
                onChange={ev => handleTitle(ev)}
                value={titleSelected}
              />
            </BoxInput>
          )}
          {typeSearch === 'PressReleases' && (
            <BoxInput>
              <TitleInput>Buscar Press-Release</TitleInput>
              <Input
                placeholder="Digite o nome da Press-Release"
                type="text"
                onChange={ev => handleTitle(ev)}
                value={titleSelected}
              />
            </BoxInput>
          )}
          {typeSearch === 'NotasDeFalecimento' && (
            <BoxInput boletimNota>
              <TitleInput>Buscar Notas de Falecimento</TitleInput>
              <Input
                placeholder="Digite o nome do Notas de Falecimento"
                type="text"
                onChange={ev => handleTitle(ev)}
                value={titleSelected}
              />
            </BoxInput>
          )}
          <Button
            type="submit"
            onClick={ev => searchPost(ev)}
            isButtonActive={isButtonActive}
          >
            Buscar
          </Button>
        </Container>
      </GridContent>
      <GridContentFlex>
        <ListImprensa
          listItens={listPostsFiltered}
          typeSearch={typeSearch}
          page={page}
          fragmentId={fragmentId}
          titleSubmited={titleSubmited}
        ></ListImprensa>
        <AcessoriaImprensa
          markdown={getMarkdown(page, 'cktsopcio85it0b74wakn5319', true)}
          fragments={getMarkdown(page, 'cktsopcio85it0b74wakn5319')}
        ></AcessoriaImprensa>
      </GridContentFlex>
    </ContainerGrid>
  );
}

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cktsok5sg83530b74e82jhx1d" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
              }
              posts(first: 9999, locales: pt_BR, orderBy: dataEHora_DESC) {
                id
                title
                date
                blogPressreleasesAssunto {
                  assunto
                }
                typeOfPost
                slugPost
                createdAt
                dataEHora
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <Search
            posts={response.gcms.site.posts}
            page={response.gcms.site.pages}
            typeSearch={props.typeSearch}
            // data={response}
            {...props}
          />
        );
      }}
    />
  );
};
