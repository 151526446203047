// Libs
import styled from 'styled-components';
// import ReactMarkdown from 'react-markdown';
// // Components
import { device } from 'components/device';
export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 4.375rem;
  width: 100vw;
  @media ${device.desktop} {
    margin-top: 0;
  }
  .MuiTab-root {
    @media only screen and (max-width: 375px) {
      margin: 0 10px 0 0;
    }
    padding: 6px 12px 6px 0;
    .MuiTab-wrapper {
      align-items: flex-start;
      opacity: 1;
      text-transform: capitalize;
      font-size: 16px;
    }
  }
  .MuiPaper-root {
    border-bottom: 1px solid #c6c8cc;
    display: grid;
    grid-template-columns: auto repeat(12, 70px) auto;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    @media ${device.desktop} {
      top: 0;
      position: relative;
      z-index: 1;
    }
    @media only screen and (max-width: 320px) {
      column-gap: 13px;
    }
    @media only screen and (max-width: 375px) {
      column-gap: 21px;
    }
    .MuiTabs-root {
      grid-column: 2 / span 12;
      @media only screen and (max-width: 375px) {
        .MuiTabs-scroller {
          width: 90vw;
          flex: 0 1 auto;
        }
      }
    }
  }
  .tabpanel {
    display: grid;
    grid-template-columns: auto repeat(12, 70px) auto;
    -webkit-column-gap: 30px;
    column-gap: 30px;
    .MuiBox-root {
      grid-column: 2 / span 12;
      padding: 0 !important;
      margin: 50px 0 !important;
      @media ${device.desktop} {
        grid-column: 2 / span 12;
      }
    }
    @media ${device.tablet} {
      display: flex;
      grid-template-columns: auto repeat(12, 70px) auto;
      align-items: center;
      justify-content: center;
    }
  }
  .MuiAppBar-colorPrimary {
    background-color: #fff;
    color: #231f20;
    box-shadow: none;
    .MuiTabs-indicator {
      background-color: #45a7df;
    }
    .MuiTab-textColorInherit.Mui-selected {
      color: #45a7df;
    }
    .MuiTab-textColorInherit {
      opacity: 1;
    }
  }
`;
