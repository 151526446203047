// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';
// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
// import PromptServiceContent from 'components/promptService/promptServiceContent';
// import MainServices from 'components/promptService/mainServices';
// import JointComissionInternational from 'components/JointCommission';
import SimpleTabs from '../../components/SimpleTabs';
import { TabsContext } from '../../context/tabs';
// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};
  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  @media only screen and (max-width: 320px) {
    padding: 0 13px;
  }
`;
const GridSearch = styled.div`
  display: flex;
  width: 100vw;
  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`;
const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${device.tablet} {
      margin-bottom: 1.875rem;
    }
    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }
  :last-child {
    margin-bottom: 0;
  }
  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
  }
  section {
    border-bottom: none;
    margin-bottom: 0;
    div {
      :last-child {
        padding-bottom: 0;
      }
    }
  }
`;
const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);
  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, tabs, location) => {
  const queryStrings = location.search
    .replace('?', '')
    .split('&')
    .filter(e => e)
    .map(query => {
      const splittedQuery = query.split('=');
      return {
        key: splittedQuery[0],
        value: splittedQuery[1],
      };
    });

  return (
    <>
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'}>
          <FeaturedMarketing
            markdown={getMarkdown(page, 'cktsqm3ts8gcr0b749yfy1qas', true)}
            // breadCrumb={getMarkdown(page, 'cktsomd9k2aes0c223qq0chib', true)}
            breadCrumb={`1. [Home](/) › [Imprensa](/imprensa/) › ${tabs}`}
            isPatologica
            firstBlobTransformDesk="translate(166%,-20%) rotate(181deg)"
            firstBlobTransformDeskLarge="translate(166%,-20%) rotate(181deg)"
            firstBlobTransformLap="translate(217%,-17%) rotate(179deg)"
            firstBlobTransformMob="translate(176%,-15%) rotate(179deg)"
            secBlobTransformDesk="translate(-141%,79%) rotate(189deg)"
            secBlobTransformDeskLarge="translate(-141%,79%) rotate(189deg)"
            secBlobTransformLap="translate(-192%,96%) rotate(-16deg)"
            secBlobTransformMob="translate(-148%,152%) rotate(191deg)"
            containerMarginBottomDesk
            titleWidthLap="30rem"
            boxInfoTextWidthDesk="28.125rem"
            boxContentPaddingBottomDesk="6.25rem"
            boxInfoParagraphImgTransformDesk="translate(0, 27%)"
            boxInfoParagraphImgWidth="unset"
            boxInfoParagraphImgHeight="unset"
            titleWidthDesk="100%"
            boxInfoWidthDesk="70%"
            marginTopTitle="0"
            titleMarginLap="0"
            containerNoPaddingTopLap
          />
        </Part>
      </GridContainer>
      <GridSearch>
        <Part>
          <SimpleTabs options={queryStrings} page={page} fragmentId="ckzn46rog87320c28ayw7oqtw" />
        </Part>
      </GridSearch>
    </>
  );
};
const Imprensa = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);
  const { tabs } = useContext(TabsContext);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop, tabs, location)}
    </Layout>
  );
};
export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cktsok5sg83530b74e82jhx1d" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Imprensa page={response.gcms.site.pages} {...props} />;
      }}
    />
  );
};
